import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import ReactAudioPlayer from "react-audio-player"

const PlayerCard = function ({ item, settings }) {
  const podcastURL = settings.podcastsURL + item.file
  let image = null
  switch (item.category) {
    case `solidarite`:
      image = (
        <StaticImage
          src="../images/ensemble-au-micro/bg-minute-solidarite.jpg"
          alt="Minute Solidarité"
        />
      )
      break
    case `plus-tard-je-serai`:
      image = (
        <StaticImage
          src="../images/ensemble-au-micro/bg-minute-plus-tard-je-serai.jpg"
          alt="Minute Plus tard je serai"
        />
      )
      break
    case `vill-agenda`:
      image = (
        <StaticImage
          src="../images/ensemble-au-micro/bg-minute-solidarite.jpg"
          alt="Minute Vill'Agenda"
        />
      )
      break
    case `expression-libre`:
      image = (
        <StaticImage
          src="../images/ensemble-au-micro/bg-expression-libre.jpg"
          alt="Expression libre"
        />
      )
      break
    case `emploi`:
      image = (
        <StaticImage
          className={`${!item.active && `grayscale`}`}
          src="../images/ensemble-au-micro/bg-minute-emploi.jpg"
          alt="Minute Emploi"
        />
      )
      break
    default:
      image = (
        <StaticImage
          src="../images/ensemble-au-micro/bg-default.jpg"
          alt="Illustration"
        />
      )
  }
  return (
    <li>
      {image}
      <h3>{item.title}</h3>
      <ReactAudioPlayer src={podcastURL} controls />
      <div>Voix : {item.voices}</div>
    </li>
  )
}

PlayerCard.propTypes = {
  item: PropTypes.shape({
    category: PropTypes.string,
    num: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    voices: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
    active: PropTypes.bool,
  }).isRequired,
  settings: PropTypes.shape({
    podcastsURL: PropTypes.string,
  }),
}

export default PlayerCard
