import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Banner from "../../components/Banner"
import PlayerCard from "../../components/PlayerCard"

const EnsembleAuMicroPodcastsPage = function ({
  data: { ensembleAuMicroPodcastsJson, settingsJson },
  location,
}) {
  return (
    <Layout>
      <Seo
        title={ensembleAuMicroPodcastsJson.title}
        description={ensembleAuMicroPodcastsJson.description}
        pathname={location.pathname}
      />
      <Banner
        background={ensembleAuMicroPodcastsJson.banner.background}
        content={ensembleAuMicroPodcastsJson.banner.content}
      />
      <div className="container">
        <section>
          <h2>{ensembleAuMicroPodcastsJson.title}</h2>
          <ul className="grid md:grid-cols-3 md:gap-5">
            {ensembleAuMicroPodcastsJson.podcasts
              .reduce((p, c) => (c.category !== `emploi` && p.push(c), p), [])
              .map(function (item) {
                return (
                  <PlayerCard
                    key={uuidv4()}
                    item={item}
                    settings={settingsJson}
                  />
                )
              })}
          </ul>
        </section>
      </div>
    </Layout>
  )
}

EnsembleAuMicroPodcastsPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default EnsembleAuMicroPodcastsPage

export const query = graphql`
  query EnsembleAuMicroPodcastsPageQuery {
    ensembleAuMicroPodcastsJson {
      title
      description
      banner {
        background {
          publicURL
        }
        content
      }
      podcasts {
        category
        num
        date
        title
        voices
        file
      }
    }
    settingsJson {
      podcastsURL
    }
  }
`
